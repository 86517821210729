import React from 'react'
import { Link } from 'gatsby'

import Image from '../Image'
import './SoinCard.scss'

const SoinCard = ({
  featuredImage,
  title,
  text,
  slug,
    href = '',
  categories = [],
  className = '',
  ...props
}) => (
  <Link to={slug} className={`SoinCard ${className}`}>
    {featuredImage && (
      <div className="SoinCard--Image relative">
        <Image lazy={false} background src={featuredImage} alt={title} />
      </div>
    )}
    <div className="SoinCard--Content">
      {title && <h3 className="SoinCard--Title">{title}</h3>}
      <div className="SoinCard--Category">
        {categories && categories.map(cat => cat.category).join(', ')}
      </div>
      {text && <div className="SoinCard--Text">{text}</div>}
    </div>
  </Link>
);

export default SoinCard
