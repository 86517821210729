import React from 'react';

import SoinCard from '../soins/SoinCard';
import './SoinsSection.scss';
import Fade from 'react-reveal/Fade';

class SoinsSection extends React.Component {
    static defaultProps = {
        soins: [],
        title: '',
        subtitle: ''
    };


    render() {
        const {soins, title, subtitle} = this.props;
        const visibleSoins = soins.filter(soin => soin.showInHomePage);

        return (
            <div className="SoinsSection">

                <div className="mt-8 p-8 container text-center">
                    <Fade>
                    {title && <h2 className="SoinsSection--Title">{title}</h2>}
                    {subtitle && <h3 className="SoinsSection--Subtitle mb-6">{subtitle}</h3>}
                    <hr></hr>
                    </Fade>

                    <Fade>
                    {!!visibleSoins.length && (
                        <div className="SoinsSection--Grid mt-8 mb-8">
                            {visibleSoins.map((soin, index) => (
                                <SoinCard key={soin.title + index} {...soin} />
                            ))}
                        </div>
                    )}
                    <a href="/soins/">
                        <button className="Button">Tous nos soins</button>
                    </a>
                    </Fade>
                </div>

            </div>
        )
    }
}

export default SoinsSection
